import React, { useState, useEffect } from 'react';
import { Button } from 'rebass';
import { navigate } from 'gatsby';
import { observer } from "mobx-react";
  
import { useCurrentVariantContext } from './CurrentVariantContext';
import strings from './strings.json';
 
import store from 'src/stores/common-store';

const ProductVariantAddToCart = ({ amount, cartUrl }) => {
  // const { addItem } = useShopifyFunctions();
  
  const addItem= store.addItem;
  const { currentVariant } = useCurrentVariantContext();
  const [disabled, setDisabled] = useState(false);

  const { productAddToCartButton, productAddToCartSoldoutButton } = strings;

  async function addToCartHandler(id, amount) {
    await addItem({ variantId: id, quantity: amount }); 
    store.showCart(); 
    // navigate(cartUrl);
  } 

  useEffect(() => {
    if (currentVariant && currentVariant.hasOwnProperty('availableForSale')) {
      currentVariant.availableForSale ? setDisabled(false) : setDisabled(true);
    }
  }, [currentVariant]);

  return (

    <div className="add-cart-div">
      <Button disabled={disabled}
        ml="auto"
        width={['300px', '400px']}
        onClick={() => {
          addToCartHandler(currentVariant.shopifyId, amount);
        }}
        variant={!disabled ? 'primary' : 'disabled'} className="add-cart-btn">
        {!disabled ? productAddToCartButton : productAddToCartSoldoutButton}
      </Button>
    </div>


  );
};

export default observer(ProductVariantAddToCart);
