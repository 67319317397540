import React, { useState, useEffect } from 'react';
import { Flex, Box, Text } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import { useCurrentVariantContext } from './CurrentVariantContext';

import formatPrice from '../../utils/formatPrice';
import strings from './strings.json';

const ProductVariantPrice = ({ initialDisplayPrice = 0, mb = 0 }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            locales
            currency
          }
        }
      }
    }
  `);
  const { locales, currency } = data.site.siteMetadata.gatsbyStorefrontConfig;

  const { productPriceLabel, productCompareAtPriceLabel } = strings;
  const { currentVariant }    = useCurrentVariantContext();
  const [ Save,setSaveValue] = useState(false);
  
  const [displayPrice, setDisplayPrice] = useState(
    formatPrice(initialDisplayPrice, locales, currency)
  );
  const [compareAtPrice, setCompareAtPrice] = useState();

  useEffect(() => {
    if (currentVariant && currentVariant.hasOwnProperty('price')) {

      if(  currentVariant.compareAtPrice &&   currentVariant.compareAtPrice>  currentVariant.price)
      {
        let saveValue=   currentVariant.compareAtPrice-  currentVariant.price;
        setSaveValue(formatPrice(saveValue, locales, currency));
      }  


      currentVariant.price
        ? setDisplayPrice(formatPrice(currentVariant.price, locales, currency))
        : setDisplayPrice(formatPrice(initialDisplayPrice, locales, currency));

      currentVariant.compareAtPrice
        ? setCompareAtPrice(
            formatPrice(currentVariant.compareAtPrice, locales, currency)
          )
        : setCompareAtPrice(false);
    }
  }, [currency, currentVariant, initialDisplayPrice, locales]);
 
  return (
    <React.Fragment>
      <div>
      {compareAtPrice && <span className="span-old-price">{compareAtPrice}</span>}
      <span className="span-new-price">{displayPrice}</span>
      {Save && <span className="span-save">Save {Save}</span>}
      </div>
       
    </React.Fragment>
  );
};

export default ProductVariantPrice;
