import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import ProductPage from './ProductPage';
import Layout from '../../components/Layout';
import PageLoader from '../../components/Page';
import styled from 'styled-components'
import AnnouncementBar from '../../custom-sections/BuyProlon/AnnouncementBar';

const StyledDiv = styled.div`
width: 100%;
background:transparent;
display:flex;
justify-content:center;
align-items: center;
flex-direction: column;
background-color: #F3F9E7;
.secton-class{
  max-width:1200px;
  margin:0 auto;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0px 12px 6px rgba(0,0,0,0.23);
}
`

export default (props) => {

  const pageData = props?.pageContext?.pageData;
  const productData = props?.pageContext?.productData;
  const { title, description, handle } = pageData;
  const body = description
  let description_seo = pageData.description;
  const { storeName } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  return (

    <Layout>
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false}>
        <meta name={description_seo} content={title} />
      </Helmet>

      <StyledDiv>
        {handle==='buy-prolon' && <AnnouncementBar />}
        <section className="flex flex-col justify-center items-center m-5 bg-white secton-class">
          <PageLoader pageData={pageData} productData={productData} page={title, body, handle} />
        </section>
      </StyledDiv>


    </Layout>
  );
};

export const productQuery = graphql`
  query _SingleProductQuery{
    store: site {
      siteMetadata { 
        gatsbyStorefrontConfig {
          storeName
          payments
          shareButtons
          gatsbyImageProps {
            loading
            fadeIn
            durationFadeIn
          }
        }
      }
    }
  }
`;
